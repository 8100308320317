@import url('https://fonts.googleapis.com/css2?&family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

:root{
  /* --lighter: linear-gradient(159deg, rgb(45, 45, 58) 0%, rgb(156, 11, 11) 100%); */
  --background: linear-gradient(to right, #494848, #2e2e2e, rgb(41, 40, 40));
  --animation-time: 10s;
  --animation-type: linear;
}

body{
  width: 100%;
  background: var(--background);
  background-size: 400% 400%;
  color: #fff;
  overflow-x: hidden;
  position: relative;
  animation: gradientAnimation var(--animation-time) var(--animation-type) infinite alternate;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.green {
  color: #e14040;
}

.slick-list, .slick-slider, .slick-track{
  padding: 1.5rem 0;
}

